.item-holder{
    width: 10%;
    text-align: center;
     }
/* .item-image{
    width: 50%;
    height:50%;
    border-radius: 80%;
    border: 2px solid #f39317;
   } */
.item-image>img{
    width: 98px;
    height:90px;
    border-radius: 80%;
    border: 1px solid #f39317;
}
.item-text{
    width: 100%;
    text-align: center;
}
.item-text>h5{
    width: 50%;
    margin-left: 25%;
}
@media screen and (max-width:558px) {
    .item-image>img{
        width: 40px;
        height:40px;
        border-radius: 80%;
        border: 0.2px solid #f39317;
        margin-left: 12.5px;
    }
    .item-text{
        width: 100%;
        text-align: center;
        margin-left: 12px;
    }
    .item-text>h5{
        font-size: x-small;
    }
}