.image-spread{
    width: 100%;
     display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow-x: scroll;
    
}
.image-spread::-webkit-scrollbar{
  height: 5px;
  border-radius: 25px;
}
.image-spread::-webkit-scrollbar-thumb{
    background-color: #f39317;
    border-radius: 12px;
}
.image-spread::-webkit-scrollbar-track{
    background-color: aliceblue;
}

.image-spread>img{
    width: 35%;
    margin-left: 3%;

}