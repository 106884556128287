.recipe-names{
    width: 100%;
    text-align: center;
    color:#1c2847;
    font-weight: 500;
    font-size: large;
    border-bottom: 2px solid #f39317;
    margin-bottom: 18px;
    padding-top: 30px;
}
.names{
    padding: 10px;
    margin-top: 10px;
}
.names>h6{
    color: #1c2847;
}
.names>h6:hover{
    color: #f39317;
}
.activeNames{
    background-color: #f39317;
    color:#1c2847;
    text-align: center;
    font-weight: 500;
}
.activeNames:hover{
    color:#1c2847;
    text-align: center;
    font-weight: 500;
    border-bottom:1px solid #1c2847;
}