:root{
  --MainOrange:'#f39317';
  --MainDark:'#1c2847'
}

.App {
  width: 100%;
  background-color:rgb(251, 252, 253);
  margin:0;
}
.btn-bg{
  background-color: #1c2847;
  color: #fff;
  border-radius: 25px;
  border: 1px solid #f39317;
  padding:8px;
  width: 100%;
  font-size: 10px;
}
.btn-Inactivate{
  background-color: #a5a6a7;
  color: #fffafa;
  border-radius: 25px;
  border: 1px solid #8f8e8e;
  padding:8px;
  width: 100%;
  font-size: 10px;
}
.btn-bg:hover{
  background-color:#f39317;
  color:#1c2847;
  border-radius: 25px;
  font-weight: 800;
  font-size: 13px;
  transition: 0.2s ease-in-out;
}
.text-main-dark-right{
  width: 40%;
  font-size: 13px;
  font-weight: 600;
  color: #1c2847;
margin-left: 60%; 
}
.text-main-dark-right:hover{
 text-decoration: underline;
 font-size: 14px;
 cursor: pointer;

}
.footer-contact{
  width: 100%;
  height: 60px;
  padding: 12px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
 margin-top: 2%;
}
.footer-contact-details{
  color:#fff;
  display: flex;
  align-items: center;
  font-size: 10px;
}
.footer-contact-details>h6{
  padding: 10px;
}
.contact-2{
  display: flex;
  width:60%;
  justify-content: space-around;
  align-items: center;
}

@media screen and(max-width:558px) {
  .btn-bg{
    /* background-color: #1c2847;
    color: #fff;
    border-radius: 25px;
    border: 1px solid #f39317; */
    padding:8px;
    width: 100%;
    font-weight: 100;
  }
  .footer-contact-details>h6{
    font-size: 6px;
  }
}
@media screen and(min-width:558px) {
  .footer-contact{
   display: none;
  }
 
}