.Row{
    margin-top: 25px;
    margin-bottom: 20px;
    min-width: 100%;
    min-height: 250px;
    padding-bottom: 20px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow-x: scroll;
    overflow-y: hidden;

}

.Row::-webkit-scrollbar{
    height:5px;
}
.Row::-webkit-scrollbar-thumb{
    background-color: #f39317;
}
.Row::-webkit-scrollbar-track{
    background-color: #fff;
}