.profile-head-container{
    width: 100%;
    height:auto;

}
.banner-img{
    width: 100%;
    height: 200px;
    position: absolute;
}
.banner-img>img{
    width: 100%;
    height: 250px;
}
.profile-banner-opacity{
    max-width: 100%;
    height: 250px;
   position: relative;
   bottom: 0px;
   background-color:  #1c1c1c;
   opacity: 0.7;
}
.profile-head-details{
    width: 100%;
    
}
.float-details{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.pic-details{
    width: 70%;
    display: flex;
    margin-left: 2%;
    position: relative;
    top: -50px;
}
.profile-edit{
    width: 28%;
}
.pic-credentials{
    margin-left: 14px;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
}

#form_pic{
   position: relative;
   top:-50px;
   left: 50px;
}
#profile_pic>img{
    width: 180px;
    height: 180px;
    border-radius: 80%;
    border: 2px solid #f39317;
}

.profile-edit>div{
    background-color: #1c2847;
    color: #f39317;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    width:50%;
}
.profile-edit>div:hover{
    background-color: #f39317;
    color: #1c2847;
   transition: ease-in-out 0.3s;
   
}
@media screen and (max-width:558px) {
    .pic-details{
      display: block;
    }
    .pic-credentials{
        margin-left: 14px;
        align-items: center;
        justify-content: center;
    }
    .profile-edit{
        width: 40%;
        
    }
   .edit-text{
    display: none;
   }
}