.desc-content{
    width:100%;
    margin-bottom: 3rem;
}
.desc-content>p{
    text-align: justify;
    font-size: 15px;
}
.eligibility{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.eligibility>h6>span{
    color: gray;
    font-size: 12px;
    font-style: italic;
}
.eligibility>button{
    border:none;
    color: #1c1c1c;
    font-weight: 600;
    border-radius: 10px;
    padding: 15px;
    font-size: 14px;
}