.parent-Nav{
    width: 80%;
    height: 80px;
    margin-left: 10%;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 0.1px solid #1c1c1c;
}

.nav-Item{
    color:#1c2847;
    text-align: center;
    font-weight: 400;
    height: 80px;
}

.nav-Item:hover{
    font-weight: 700;
    font-size: 18px;
    border-bottom: 0.3px solid #f39317;
    transition: ease-in-out 0.3s;
    cursor: pointer;
}

.activeItem{
    font-weight: 700;
    font-size: 18px;
    border-bottom: 0.3px solid #f39317;
}