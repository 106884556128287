.holder{
    width: 100%;
    height: 250px;
    margin-bottom: 1%;
}
.bg-img{
    width: 100%;
    position:absolute;
    height: auto;
}
.bg-img>img{
    width: 100%;
    height:250px;
}
.opacity{
    background-color:#1c1c1c;
    height: 250px;
    max-width: 100%;
    opacity: 0.9;
}
.content{
    color: #fff;
    text-align: center;
    height: auto;
    position: relative;
    bottom: 200px;
    
   }
   
.ca_items{
    width: 100%;
    display: flex;
    overflow-x: scroll;
    justify-content: space-evenly;
    padding-left: 15px;
}
.ca_items::-webkit-scrollbar{
    height: 2px;
}
@media screen and(max-width:558px) {
    .heading{
        margin-bottom: 18px;
    }
}