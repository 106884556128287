.banner-holder-full{
    width: 100%;
    height: 200px;
    margin-bottom: 1%;
}
.bannerBg-img{
    width: 100%;
    height: 100%;
    position: absolute;
}
.bannerBg-img>img{
    width: 100%;
    height: 200px;
   
}

.cover-opacity{
    background-color: #000;
    opacity: 0.8;
    width: 100%;
    height: 100%;
}
.head-title{
    position: relative;
    bottom: 100px;
    width: 100%;
    text-align: center;
    color: #fff;
}