#info>form>.form-group>input{
    border: none;
    background-color: transparent;
}
#info>form>.form-group>select{
    border: none;
    background-color: transparent;
}

.country-flag{
    width: 80%;
    margin-left: 10%;
    display: flex;
    justify-content: space-evenly;
    height: 40px;
}
.country-flag>.emoji-grp{
    width: 30%;
}
.country-flag>input{
    width: 60%;
}

.emoji{
    width:100%;
    display: flex;
    justify-content: space-around;
    border:1px solid #333;
    height: 40px;
    border-radius: 5px;
    align-items: center;
}
.emoji-grp>.emoji-dropdown{
    width: 100%;
    max-height: 120px;
    margin-top: 12px;
    border-radius: 5px;
    background-color: #fff;
   
}
.emoji-grp>div>ul{
    list-style-type: none;
    width: 100%;
}
.emoji-grp>div>ul>li{
    width: 100%;
   align-items: center;
   padding: 5px;
 }
.emoji-grp>div>ul>li:hover{
        background-color: #f39317;
        color:#1c2847;
        font-weight: 400;
 }
