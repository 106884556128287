.crumb-holder{
    margin-left: 2%;
    display: flex;

}
.crumb-holder>h6{
    font-size: 11px;
    color: grey;
    font-weight: 600;
    margin-bottom: 2rem;
}
.crumb-holder>h6>a{
    font-size: 11px;
    color: grey;
    font-weight: 600;
     text-decoration: none;
}
.crumb-holder>h6>a:hover{
    font-size: 11px;
    color: grey;
    font-weight: 600;
     text-decoration: underline;
}