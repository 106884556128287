#carouselExampleIndicators{
    width: 100%;
    height:400px;
} 
.carousel-item>img{
    width: 100%;
    height:400px;
}
.carousel-indicators{
    background: color #1d1d1d;;
}