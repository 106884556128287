.reset-password-container{
    width: 100%;
    min-height: 150px;
    background-color:rgba(255, 255, 255,0.8)
}
.reset-password-container>h3{
    text-align: center;
    font-size: 19px;
    font-weight: 700;
    margin-top: 1%;
    margin-bottom: 2%;
    padding: 18px;
    text-decoration:underline;
}
.reset-password-submit{
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 12px;
}
.error-email{
    font-size: 10px;
    color: red;
}
.response-bar{
    width: 100%;
    padding:18px;
    
}