.form{
    width: 100%;
    height: auto;
    background-color: rgba(238, 242, 245,0.6);
    border-radius: 10px;
}
.form-title{
    width: 80%;
    margin-left: 10%;
    height:auto;
    padding:10px
}
.form-title>h4{
    color: #1d1d1d;
    font-size: 22px;
    font-weight: 700;
    font-family: 'Helvetica';
    text-align: left;
}
.form-title>h6{
    color: #1d1d1d;
    font-size: 11px;
    font-weight: 700;
    text-align: left;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.form-group{
    margin-top: 10px;
    margin-left: 5%;
}
.form-group>input{
    width: 90%;
}
.Login-form{
    margin-top: 10px;
}
.SignUp-form{
    margin-top: 10px;
}
.submit-grp{
    width: 80%;
    margin-left: 7%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  
}
.form>p{
    font-size: 12px;
    color: #1d1d1d;
    margin-bottom: 1rem;
    padding: 12px;
}
.form>p>span{
    font-weight: 800;
}
.form>p>span:hover{
    text-decoration: underline;
    cursor: pointer;
}