.recipe-header{
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
}
.recipe-header>h4{
    text-align: center;
}
.underliner{
    background-color: #f39317;
    border-radius: 25px;
    width:50%;
    height: 5px;
    margin-left: 25%;
}
.item-option{
    color: red;
    font-size: 10px;
}
.recipe-process{
    width:100%;
    
}
#iframe-video{
    margin-left: 25%;
}
.link-yt{
width: 100%;
text-align: center;
}
.link-yt>span{
    font-weight: 500;
}
.link-yt>a{
    font-weight: 500;
    text-decoration: none;
    color:#fff;
    background-color: rgb(187, 7, 7);
    border: none;
    border-radius: 10px;
    padding: 8px;
    text-align: center;
    outline: none;
}
.link-yt>a:hover{
    font-weight: 500;
    text-decoration: none;
    color:#fff;
    background-color: rgb(233, 9, 9);
    border: none;
    border-radius: 10px;
    padding: 8px;
    text-align: center;
    transition: 0.3s ease-in;
}
.recipe-body-text{
    margin-top: 20px;
}

.recipe-body-text>h5{
    color: #f39317;
    text-decoration: underline;
    margin-bottom: 12px;
}

@media screen and (max-width:558px) {
    #iframe-video{
        margin-left: 0;
    }
    #iframe-video>iframe{
        width: 100%;
    }
}