.product-slide{
    margin-left: 7px;
    margin-top: 15px;
}
.product-btn-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
 }
  .product-offer-btn{
    width:68%;
    }
  .product-price-offer{
    width:28%;
    }
 .product-price-offer>span{
    font-size:12px;
  }
  .product-price{
    font-weight: 600;
    color:#f39317;
    font-size: 14px;
  }
.product-price:hover{
    text-decoration: underline;
    font-size: 13px;
    transition:0.2s ease-in-out;
    cursor: pointer;
}
.product-description{
  font-size: 13px;
  font-weight: 500;
}
.product-title{
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width:558px) {
  .product-slide{
    margin-left: 0;
    margin-top: 15px;
}
 .product-offer-btn{
    width:60%;
    }
  .product-price-offer{
    width:38%;
    }
.product-title{
  font-size: 14px;
}
.product-description{
  font-size: 13px;
 }
}