.filtered-container{
    width: 100%;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
}

.filtered-products{
 width: 50%;
 margin-top: 2px;
 margin-left: 2%;
 }
 