.no-item-container{
    width: 100%;
    height: 350px;
    margin-top: 1%;
}
.no-item-container>p{
    text-align: center;
    font-size: 12px;
    color: gray;
    font-weight: 400;
    font-style: italic;
}