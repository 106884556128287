.video-slide-container{
    width: 100%;
   
}

.video-show{
    width: 100%;
    height:14rem;
    background-color: black;
}

.video-show>video{
    width: 100%;
    
}
@media screen and (min-width:900px) {
    .video-show>video{
       display: none;
    }
    .video-placeholder{
        background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsw2WCig_8IArjWIjqJa39P1YVRRH55T85Ag&usqp=CAU');
        width: 100%;
        height: 100%;
        background-size: contain;
        
    }
}