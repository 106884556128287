.container-wrapper{
    width: 100%;
    height: 500px;
    margin-bottom: 1%;
}
.imgHolder{
    width: 100%;
    position:absolute;
    height: auto;
 
}
.imgHolder>img{
    width: 100%;
    height:500px;
}
.auth_opacity{
    max-width: 100%;
    height: 500px;
    background-color: #1d1d1d;
    opacity: 0.7;
}

.auth_content{
    width: 100%;
    height: auto;
    position: relative;
    bottom: 450px;
    
   
}
.btn-Inactive{
    background-color: grey;
    color: aliceblue;
    border: none;
    width:80%;
    border-radius: 12px;
    height:30px
}
.error-message{
    color: red;
    font-size: 10px;
    font-weight: 500;
}
.field-error{
    border: 1px solid red;
}
@media screen and (max-width:558px) {
    .content-carousel{
        display: none;
    }
}
