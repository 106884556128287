.cart-item-holder{
    overflow-y:scroll;
    height:300px;
    padding-top:15px;
    padding: bottom 15px;
}

.cart-item-holder::-webkit-scrollbar{
    width:5px;
}
.cart-item-holder::-webkit-scrollbar-thumb{
    background-color: #f39317;
    border-radius: 12px;
}
.cart-item-holder::-webkit-scrollbar-track{
    background-color: #fff;
}

@media screen and (min-width:558px) {
    .cart-item-holder{
        overflow-y:scroll;
        height:400px;
        padding-top:15px;
        padding: bottom 15px;
    }
}