.checkout-container{
    width: 100%;
    background-color :rgba(51, 51, 51,0.4);
    padding: 8px;
}
.checkout-body{
    width: 100%;

}
.checkout-summary-title{
    background-color: #1c2847;
    color: #f39319;
    text-align: center;
    font-weight: 700;
}
.checkout-summary-body{
 
}
.checkout-summary-subtotal{
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items: center;
    color:#1c2847;
    /* border-bottom: 0.1px solid #1c2847; */
    padding: 4px;
}
.checkout-summary-subtotal>h5{
    font-size: 13px;
    font-weight: 700;

}
.checkout-summary-subtotal>h3{
   color:#f39319;
   background-color: #1c2847;
   padding: 8px;

}
.content-container{
    width:100%;
}
.content-flex{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
   align-items: center;
   border-bottom: 0.1px solid #7a7979;
}