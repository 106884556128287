.place-holder{
    width: 100%;
    height: auto;
    overflow-y: scroll;
}
.place{
    width: 100%;
    height:auto;
    border-bottom: 0.2px solid #333;
    margin-top: 8px;
    padding: 8px;
}
.place>p>{
    font-size: 11px;
}
.place>p>span{
    font-weight: 500;
}

.place:hover{
    background-color: rgb(247, 245, 245);
    border-right: 2px solid #f39317;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.placeSwitch{
    border: none;
    background-color: transparent;

}
.placeSwitch>a:hover{
    color:#f39317;
    cursor: pointer;

}
.placeSwitch:hover{
  color:#f39317;
  cursor: pointer;
 
}
.place-active{
    background-color: rgb(247, 245, 245);
    border-right: 2px solid #f39317;
    border-bottom: none;
  
}
.horaires{
    padding: 8px;
}
.horaires-period{
    width: 100%;
    text-align: center;
}
.horaires-period>hr{
    color: #f39317;
    border: 1px solid #f39317;
    width: 50%;
    margin-left: 25%;
}
.horaires-period-body{
    width: 100%;
    height: auto;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}
.place-holder::-webkit-scrollbar{
    width:5px;
}
.place-holder::-webkit-scrollbar-thumb{
    background-color: #f39317;
}
.place-holder::-webkit-scrollbar-track{
    background-color: #fff;
}